<template>
	<div>
		
		<div class="stepper-md">
			<div class="mdl-card mdl-shadow--2dp">
				<div class="mdl-card__supporting-text">
					<div class="mdl-stepper-horizontal-alternative">
						<div v-for="(item, key) in this.tabsPanel"
							:class="['mdl-stepper-step', item.selected ? 'active-step' : '', item.finished ? 'step-done' : '' ]" :key="`tab-${key}`">
							<div class="mdl-stepper-circle"><span>{{ item.id }}</span></div>
							<div class="mdl-stepper-title"> {{ item.name }} </div>
							<div class="mdl-stepper-bar-left"></div>
							<div class="mdl-stepper-bar-right"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-show="this.tabsPanel[0].selected && !this.tabsPanel[0].finished" :key="`A-${1}`">
				
				<div class="col-md-8 mx-auto">
					<h3>{{ $t('ConnectYourGravityForms')}}</h3>
					
					<p><a href="https://wordpress.org/support/article/managing-plugins/#installing-plugins" target="_blank">{{ $t('Steps')}}</a> {{ $t('OurPlugin')}}
					</p>
					
					<center>
						<a class="btn px-6 py-3 btn-danger" href="https://wordpress.org/plugins/ohmylead-add-on-gravity-forms/" target="_blank">{{ $t('DownloadOurPlugin')}}</a>
					</center>
					
					<div class="row">
						<div class="col-md-12 text-right">
							<b-button variant="info" size="sm" @click="stepNext(2)">{{$t('Next')}} <i class="fa fa-chevron-right"></i> </b-button>
						</div>
					</div>
				</div>
				
			</div>
			<div class="row" v-show="this.tabsPanel[1].selected && !this.tabsPanel[1].finished" :key="`A-${2}`">
				<div class="col-md-8 mx-auto">
					
					<div class="alert alert-warning" role="alert">
						{{$t('RememberActivate')}}
						
					</div>
					
					<div class="collapse-md">
						<div :class="['form-group', validate ? 'has-error' : '']">
							<label for="nameSource">{{$t('NameYourSource')}}:</label>
							<input type="text" class="form-control" id="nameSource" placeholder="Lead source will have the same name" v-model="nameSource" />
							<small v-if="validate" class="help-block">{{ $t('PleasProvideNameSource')}}</small>
						</div>
						
						<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="p-1 d-flex" role="tab">
								<b-button block v-b-toggle.accordion-1 variant="light" class="text-left"><i class="fa fa-bell-o"></i> {{ $t('LeadAlert')}}</b-button>
								<b-form-checkbox v-model="notificationArray.active" name="check-button" switch size="lg" class="mt-1"></b-form-checkbox>
							</b-card-header>
							<b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
								<b-card-body class="border-top">
									<b-card-text>
										<lead-alert-tabs :notificationArray="notificationArray"></lead-alert-tabs>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>
						
						<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="p-1 d-flex" role="tab">
								<b-button block v-b-toggle.accordion-2 variant="light" class="text-left"><i class="fa fa-envelope-o "></i> {{ $t('WelcomeEmail')}}</b-button>
								<b-form-checkbox v-model="confirmationArray.active" name="check-button" switch size="lg" class="mt-1"></b-form-checkbox>
							</b-card-header>
							<b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
								<b-card-body class="border-top">
									<b-card-text>
										<welcome-email-tabs :confirmationArray="confirmationArray"></welcome-email-tabs>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>
						
						<div class="row">
							<div class="col-md-6">
								<b-button size="sm" @click="stepPrevious(0)"><i class="fa fa-chevron-left"></i> {{$t('Previous')}}</b-button>
							</div>
							<div class="col-md-6 text-right"><!--  v-if="nameSource != ''" -->
								<b-button variant="success" size="sm" @click="saveSourceContinue">{{$t('SaveAndContinue')}} <i :class="[ loading ? 'fa fa-spinner fa-spin' : '' ]"></i></b-button>
							</div>
						</div>
					</div>
					
				</div>
			</div>
			<div class="row" v-show="this.tabsPanel[2].selected && !this.tabsPanel[2].finished" :key="`A-${3}`">
				<div class="col-md-8 mx-auto">
					<div class="collapse-md">
						
						<!--div class="row">
							<div class="col-md-12">
								<b-button class="mr-2" @click="stepPrevious(1)"><i class="fa fa-chevron-left"></i> Previous</b-button>
							</div>
						</div-->
						
						<div class="row notif_section_sending">
							<div class="col-12 notification_tabd">
								<div class="text-center">
									<div class="col-12 gravity-add_url facebook_step_2 text-left">
										<label class="control-label" for="webhook-url">{{$t('WebhookLink')}}</label>
										<div class="input-group">
											<input type="text" v-model="url" readonly class="form-control" />
											<button type="button" @click="doCopy" class="btn btn-primary rounded-0">{{$t('CopyLink')}}</button>
										</div>
										<label class="control-label">{{$t('Instructions')}}</label>
										<p v-html="$t('GravityInstructions')">

										</p>
										<p style="margin-bottom: 50px;">
											<router-link :to="{name: 'mySources'}" class="btn btn-primary">
												<i class="fa fa-chevron-left"></i> {{ $t('ReturnToLeadList')}}
											</router-link>
										</p>
									</div>
								</div>
							</div>
						</div>
						
					</div>
					
				</div>
			</div>
		</div>
		<br><br><br><br><br>
		
	</div>
</template>
<script>
	// Landing-Page-Framework/core/Modules/LeadSources/Resources/assets/js/modules/sources/components/Webhooks/Providers/gravityforms.vue
	// Landing-Page-Framework/core/Modules/Webhooks/Http/Controllers/Api/WebhooksController.php
	
	// import findIndex from 'lodash/findIndex'
	// import each from 'lodash/each'
	
	import leadAlertTabs 	from '@/components/sections/LeadAlertTabs.vue'
	import welcomeEmailTabs from '@/components/sections/WelcomeEmailTabs.vue'
	
	// import axios from 'axios'
	
	export default {
		name		: 'GravityForms',
		components	: {
			
			leadAlertTabs,
			welcomeEmailTabs
		},
		data() {
			return {
				
				url: 'Copy These Text',
				
				notificationArray: {
					active		: true,
					type 		: 'notification',
					subject		: '',
					from_email	: '',
					from_name	: '',
					recipients	: [],
					content		: '',
				},
				confirmationArray: {
					active		: false,
					type		: 'confirmation',
					from_email	: '',
					from_name	: '',
					subject		: '',
					content		: ''
				},
				
				nameSource		: '',
				tabsPanel		: [
					{ id:1, name:this.$t('DownloadPlugin'), selected: true,  finished: false },
					{ id:2, name:this.$t('Settings'), selected: false, finished: false },
					{ id:3, name:this.$t('WebhookLinkInstructions'), selected: false, finished: false },
				],
				pages	: [],
				forms	:	[],
                user	: {},
                validate: false,
                loading: false,
                
			}
		},
		methods: {
			
			notifyMe(message, variant){
				this.$notify({
					group	: 'foo',
					// title: 'Important message',
					text	: message,
					type	: variant,
					// duration: 5000,
					// speed	: 1000
				});
				
			},
			
			saveSourceContinue() {
				//Reset errors
				const that = this
				
				let dataSave = {
					
					sourceName		: that.nameSource,
                    notification	: that.notificationArray,
                    confirmation	: that.confirmationArray
                };
				that.validate = false
				if( that.nameSource != '' ) {
					that.loading = true
					// that.arrayResult.data = []
					
					let url   = 'save_source_gravity_forms'
					let method= 'POST'
					let data  = dataSave
					
					that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
						
						that.loading = false
						that.url = window.location.origin + '/webhooks/' + response.data.webhook.id;
						that.stepNext(3);
						
						that.notifyMe(this.$t('WebhookSuccessfullyAdded'), 'success')
						
					}).catch(( {response} ) => {
						console.log(response)
					})
					
				} else {
					that.validate = true
				}
			},
			
			
			doCopy: function () {
				this.$copyText(this.url).then(function (e) {
					alert('Copied')
					console.log(e)
				}, function (e) {
					alert('Can not copy')
					console.log(e)
				})
			},
			
			stepPrevious(element) {
				
				this.tabsPanel.forEach( function( el, key ) {
					if( element < key ) {
						el.selected = false
						el.finished = false
					}
				} );
				
				this.tabsPanel[element].selected = true;
				this.tabsPanel[element].finished = false;
				
			},
			
			stepNext(element) {
				
				this.tabsPanel.forEach( function( el ) {
					
					if( element > el.id ) {
						el.selected = true
						el.finished = true
					} else if( element == el.id ) {
						el.selected = true;
						el.finished = false;
					} else {
						el.selected = false
						el.finished = false
					}
					
				} );
				
			},
			
		},
		
		mounted() {
			
        },
		computed: {
            
		},
	}
</script>
<style lang="scss">
	.doc-img {
		width: 100%;
	}
	
	
	.has-error {
		.form-control {
			border-color: #a94442;
			-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
			box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
		}
	}
	
	
</style>