<template>
	<div class="container-fluid">
		
		<div class="event_processing" v-show="loadingSources">
			<!--i class="fa fa-spinner fa-spin fa-3x fa-fw"></i-->
			<img src="/img/ajax-loader.gif" />
		</div>
		
		<div class="sourceline-item" v-if="typeof $route.params.source == 'undefined'">
			
			<div class="topfilter mb-2">
				<p class="title m-0">{{ title }} </p>
			</div>
			<div class="title-desc" v-html="$t('DemoDataTopTitle')"></div>
			<!--template v-if="DemoDataPanel">
				<div class="alert alert-info row mb-4 mx-0">
					<div class="col-1 my-auto text-center">
						<img src="/img/icon/list-ul.png" style="width: 38px;" />
					</div>
					<div class="col-7 py-4">
						<div>
							<b style="font-size: 14px;">{{ $t('DemoDataStart') }}</b>
							<div style="font-size: 12px;">{{ $t('DemoDataTitle') }}</div>
						</div>
					</div>
					<div class="col-4 text-right my-auto pr-0">
						<button class="btn btn-upgrade" style="height: 46px;" @click="DemoData()">
							{{ $t('StartNow') }} <i :class="['fa', LoadingDemoData ? 'fa-spinner fa-spin' : 'fa-chevron-right']"></i>
						</button>
					</div>
					<i class="fa fa-close c-pointer" style="position: absolute;right: 16px;top: 10px;" @click="DemoDataPanel = false"></i>
				</div>
			</template-->
			
			<div class="row text-center mt-3">
				<div class="col-md-3 col-lg-3 mb-4" v-if="DemoDataPanel">
					<a href="javascript:void(0)" class="new-sources-md row m-0">
						<div class="col-md-12 my-auto">
							<img src="/img/icon/list-ul.png" class="logo-integration" />
						</div>
						<div class="col-md-12 my-auto">
							<label class="my-auto">{{ $t('DemoDataStart') }}</label>
							<p class="my-auto">
								
							</p>
						</div>
						<div class="col-md-12 m-auto">
							<button class="btn btn-upgrade" @click="DemoData()" :disabled="LoadingDemoData">
								{{ $t('Start') }} <i :class="['fa', LoadingDemoData ? 'fa-spinner fa-spin' : 'fa-chevron-right']"></i>
							</button>
						</div>
					</a>
				</div>
				
				<div class="col-md-3 col-lg-3 mb-4" v-for="(item, key) in arraySources" v-bind:key="key">
					
					<a class="new-sources-md row m-0" v-if="item.viaZapier" target="_blank" @click="OpenLink(item.url)">
						<img src="/img/svg/By-Zapier.svg" style="position: absolute;right: 22px;top: 16px;" />
						<div class="col-md-12 my-auto">
							<img class="logo-integration" :src="'/img/Integration/sources/' + item.logo" />
						</div>
						<div class="col-md-12 my-auto">
							<label v-text="item.title" class="my-auto"></label>
							<p class="my-auto">
								<!-- {{ item.description }} -->
								<!-- {{ $t('DemoDataReceive', {title:item.title}) }} -->
							</p>
						</div>
						<div class="col-md-12 m-auto">
							<button class="btn btn-upgrade">{{ $t('Connect') }}</button>
						</div>
					</a>
					<a href="javascript:void(0)" class="new-sources-md row m-0" v-else-if="item.name == 'website'">
						<div class="col-md-12 my-auto">
							<img class="logo-integration" :src="'/img/Integration/sources/' + item.logo" />
						</div>
						<div class="col-md-12 my-auto">
							<label v-text="item.title" class="my-auto"></label>
							<p class="my-auto">
								<!-- {{ item.description }} -->
							</p>
						</div>
						<div class="col-md-12 m-auto">
							<button class="btn btn-upgrade">{{ $t('Connect') }}</button>
						</div>
					</a>
					<router-link :to="{ path : '/newSource/' + item.name, params: {source: item.name} }" class="new-sources-md row m-0" v-else>
						<div class="col-md-12 my-auto">
							<img class="logo-integration" :src="'/img/Integration/sources/' + item.logo" />
						</div>
						<div class="col-md-12 my-auto">
							<label v-text="item.title" class="my-auto"></label>
							<p class="my-auto">
								<!-- {{ item.description }} -->
							</p>
						</div>
						<div class="col-md-12 m-auto">
							<button class="btn btn-upgrade">{{ $t('Connect') }}</button>
						</div>
					</router-link>
				</div>
			</div>
		</div>
		
		<div v-else>
			<div class="topfilter">
				<p class="mt-2 title">{{ title }} </p>
			</div>
			
			<div class="row">
				<div class="col-12">
					<facebook-lead-ads v-if="$route.params.source == 'facebook'"></facebook-lead-ads>
					<import-excel-file v-if="$route.params.source == 'excel'"></import-excel-file>
					<webhook-api v-if="['clickFunnels', 'webhook', 'landingi', 'googleLead'].includes($route.params.source)"></webhook-api>
					<gravity-forms v-if="$route.params.source == 'gravity'"></gravity-forms>
				</div>
			</div>
		</div>
		
		<b-modal id="modal-register-check" centered size="md" hide-footer hide-header v-if="!$store.state.WhiteLabel">
			<div class="upgrade-div">
				<!--img src="/img/svg/times.svg" class="pull-right c-pointer" /-->
				<i class="fa fa-close pull-right c-pointer" @click="$bvModal.hide('modal-register-check')"></i>
				
				<div class="text-center">
					<img src="/img/logo.png" class="upgrade-img mt-5" style="width: 8em;" />
					<h3>{{ $t('PopupDTitle') }}</h3>
					<p class="px-5 p-upgrade">{{ $t('PopupDMessage') }}</p>
					<!--div><button @click="SeePlans" class="btn btn-outline-upgrade btn-upgrade-size">SEE PRICING & PLANS</button></div-->
					<div>
						<button class="btn btn-upgrade btn-upgrade-size" @click="DemoData()">
							{{ $t('PopupDButton') }} <i :class="['fa', LoadingDemoData ? 'fa-spinner fa-spin' : 'fa-chevron-right']" style="vertical-align: middle;"></i>
						</button>
					</div>
				</div>
			</div>
		</b-modal>
		
	</div>
</template>
<script>
	import facebookLeadAds from './sources/FacebookLeadAds.vue'
	import importExcelFile from './sources/ImportExcelFile.vue'
	import gravityForms from './sources/GravityForms.vue'
	import WebhookApi 	from './sources/WebHookAPI.vue'
	
	import Pusher 		from "pusher-js"
	
	export default {
		name:'newSource',
		components: {
			//
			
			// fileExcel.blade.php
			// By deleting the leads.csv-2020-01-27 18:39:27, all leads inside will also be deleted, Are you sure you want to continue? -----------------------------------------------------------------
			facebookLeadAds,
			importExcelFile,
			gravityForms,
			WebhookApi
		},
		data() {
			return {
				//
				loadingSources	: true,
				zapierApi		: '',
				title	    	: this.$t('ConnectSource'),
				arraySources	: [],
				
				/*
				arraySources: [
					{ id:0,  title:'Facebook Lead Ads', 	  name: "facebook", 	selected: false, viaZapier: false, logo:'facebook-lead-ads.png' },
					{ id:1,  title:'Excel', 				  name: "excel", 		selected: false, viaZapier: false, logo:'excel.png' },
					// { id:1,  title:'Gravity Forms',			  name: "gravity",  	selected: false, viaZapier: false, logo:'gravity-forms.png' },
					// { id:2,  title:'OML Webform (Beta)',	  name: "webform",		selected: false, viaZapier: false, logo:'300.png' },
					// { id:4,  title:'OML Landing page', 		  name: "landing", 		selected: false, viaZapier: false, logo:'300.png' },
					{ id:5,  title:'Linkedin Lead Gen Forms', name: "linkedin", 	selected: false, viaZapier: true, logo:'linkedin-leads-gen.png' },
					// { id:6,  title:'Clickfunnels', 			  name: "clickfunnels", selected: false, viaZapier: false, logo:'clickfunnels.png' },
					// { id:8,  title:'Google Lead form', 		  name: "googleLead", 	selected: false, viaZapier: true, logo:'google-lead-form.png' },
					// { id:9,  title:'Landingi', 				  name: "landingi", 	selected: false, viaZapier: false, logo:'landingi.jpg' },
					// { id:10, title:'Unbounce', 				  name: "unbounce", 	selected: false, viaZapier: false, logo:'unbounce.png' },
					// { id:11, title:'TypeForm', 				  name: "typeForm", 	selected: false, viaZapier: true, logo:'typeForm.png' },
					// { id:12, title:'Google Sheets', 		  name: "googleSheets", selected: false, viaZapier: true, logo:'google-sheets.png' },
					// { id:13, title:'Webhooks', 				  name: "webHooks", 	selected: false, viaZapier: false, logo:'webhook.png' },
					// { id:14, title:'Zoho Forms', 			  name: "zohoForms", 	selected: false, viaZapier: true, logo:'zohoforms.png' },
					// { id:15, title:'Cognito Forms', 		  name: "cognito", 		selected: false, viaZapier: true, logo:'cognitoforms.png' },
					// { id:16, title:'JotForm', 				  name: "jotForm", 		selected: false, viaZapier: true, logo:'jotForm.png' },
					// { id:17, title:'Wufoo', 				  name: "wufoo", 		selected: false, viaZapier: true, logo:'wufoo.png' },
					// { id:18, title:'Ninja Forms', 			  name: "ninjaForms", 	selected: false, viaZapier: true, logo:'ninja_form.png' },
				]
				*/
				
				DemoDataPanel	: false,
				LoadingDemoData	: false,
			}
		},
		methods: {
			GetInit() {
				let that  = this
				
				let url   = 'newsource_info'
				let method= 'GET'
				let data  = {}
				that.$store.dispatch( 'apiRequest', { url, method, data } ).then((response) => {
					
					that.DemoDataPanel = ( !response.data.demoData && response.data.leadsCount == 0 )
					
					let action = localStorage.getItem('action')
					if ( that.DemoDataPanel && action == 'register' ) {
						that.$bvModal.show('modal-register-check')
						localStorage.removeItem('action')
					}
					
				}).catch(() => {
					
				})
				
			},
			DemoData() {
				let that  = this
				
				let url   = 'demo_data'
				let method= 'POST'
				let data  = {}	
				that.LoadingDemoData = true
				
				that.$store.dispatch( 'apiRequest', { url, method, data } ).then((response) => {
					let source_id = response.data.source_id
					console.log(response.data)
					that.LoadingDemoData = false
					window.localStorage.setItem('DemoData', 'first')
					that.$router.push({path: '/myleads/' + source_id, params: {source: source_id}})
					// that.$router.push({path:'/myleads'})
					
				}).catch(() => {
					that.LoadingDemoData = false
				})
				
			},
			OpenLink(link) {
				window.open(link, 'Popup', 'scrollbars=1,resizable=1,height=560,width=770');
			},
			getSourcesTypes() {
				let that = this
				let url   = 'get_sources_types'
				let method= 'GET'
				let data  = {}	
				that.$store.dispatch( 'apiRequest', { url, method, data } ).then((response) => {
					that.zapierApi    = response.data.sources_types.zapierApi
					that.arraySources = response.data.sources_types.types
					
					// that.arraySources.push(
						// { id: that.arraySources.length + 1, title:'Your Website', name: "website", selected: false, logo: 'web_site.png' }
					// )
					
					that.loadingSources = false
				}).catch(( {response} ) => {
					console.log(response.data)
					that.loadingSources = false
				})
			}
		},
		mounted() {
			let that 	= this
			
			// console.log( that.$route.params.source )
			
			var pusher 	= new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
				cluster	: process.env.VUE_APP_PUSHER_APP_CLUSTER,
				forceTLS: true
			});
			var channel = pusher.subscribe('zapier_input' + JSON.parse(localStorage.getItem('oml-client')).id + '_src');
            channel.bind('zapier_input_src', function(data) {
                if( data.success ) {
					that.notifyMe('<div id="zapier-source">Success<input id="input-source" type="hidden" value="' + data.source_name + '" /></div>', 'success')
				}
            });
            
			that.getSourcesTypes()
			that.GetInit()
			that.$bvModal.show('modal-black-friday')
			that.$store.state.selectedPage = this.$route.name
			// that.$router.push({path: '/myleads/' + '198', params: {source: '198'}})
		}
	}
</script>
<style lang="scss">
	
	.sourceline-item {
		
		.title-desc {
			padding-bottom: 1em;
			font-size: 14px;
		}
		
		p {
			font-size: 12px;
		}
		
		.alert-info {
			/* color: #007483; */
			background-color: #e6fcff !important;
			border-color: #d9fbff !important;
		}
		
	}
	
	
	.new-sources-md {
		border: 1px solid #eee;
		box-shadow: 0px 1px 6px #ccc;
		padding: 18px 12px 12px 12px;
		height: 240px;
		cursor: pointer;
		color: #484848;
		
		.logo-integration {
			height: 55px;
		}
		
		label {
			font-size: 14px;
			font-weight: bold;
		}
		
		.viaZapier {
			position: absolute;
			right: 22px;
			top: 8px;
			max-width: 35px;
			
		}
	}
	.shadow-md {
		box-shadow: 0px 0px 5px #e4e4e4;
	}
</style>