<template>
    <div class="container-fluid">
		
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="row topfilter">
						<div class="col-6 my-auto title">
							<router-link :to="{ name : 'mySources' }"><i class="fa fa-chevron-left" style="color:#377cff;font-size: 21px; vertical-align: middle;"></i> {{ $t('Back') }}</router-link> | {{ $t('LeadSources') }}
						</div>
					</div>
				</div>
				<div class="col-md-12">
					<!--source-settings :tabsActive="tabs" :selectedSource="selectedSource"></source-settings-->
					
					<div class="custom-tabs">
						<b-tabs content-class="mt-3" class="border-top">
							<!--b-tab active>
								<template v-slot:title>
									<i class="fa fa-random"></i> Connect to Facebook
									
									<span content="Edit your Connect Facebook" info-tooltip="hover me"><i class="fa fa-info-circle"></i></span>
								</template>
								<div class="col-md-8 mx-auto">
									<div class="row">
										<center class="col-12 mb-5 mt-5">
											<h3 class="mb-5">Edit your Connect Facebook</h3>
											<facebook-components></facebook-components>
										</center>
									</div>
								</div>
							</b-tab-->
							<b-tab :active="(tabsActive == 'webhook_edit') ? true : false" v-if="tabsActive == 'webhook_edit'"><!-- -->
								<template v-slot:title>
									<i class="fa fa-edit"></i> {{ $t('Setting') }}
								</template>
								<div class="col-md-8 mx-auto">
									<div class="row">
										<div class="col-12 my-auto"><b>{{$t('WebhookLink')}}</b></div>
										<div class="col-12 my-auto">
											<input type="text" class="form-control" v-model="WebhookLink" :placeholder="$t('WebhookLink')" />
										</div>
										<div class="col-12 pt-3" style="font-size: 14px;" v-html='$t("instructionWebhook")'>
											
											
										</div>
									</div>
								</div>
							</b-tab>
							<b-tab :active="(tabsActive == 'edit') ? true : false" v-if="tabsActive != 'webhook_edit'"><!-- -->
								<template v-slot:title>
									<i class="fa fa-edit"></i> {{ $t('Mapping') }}
									<span content="Map and match your fields with the qualification interface" info-tooltip="hover me"><i class="fa fa-info-circle"></i></span>
								</template>
								<div class="col-md-8 mx-auto">
									<div class="row">
										<div class="col-12">
											<fields-mapping 
											:questionsFields="questionsFields"
											:fields="fields"
											:mapping_fields="mapping_fields"
											:fieldsLabel="fieldsLabel" @updateFieldValue="updateFieldValue" :DisableFields="true">
											</fields-mapping>
										</div>
										<div class="col-12 text-right mb-2">
											<!--button class="btn btn-success" @click="saveSettings()">
												<i class="fa fa-spinner fa-spin" v-if="loading"></i>
												<i class="fa fa-cogs" v-else></i> Save
											</button-->
										</div>
									</div>
								</div>
							</b-tab>
							<b-tab :active="(tabsActive == 'alert') ? true : false">
								<template v-slot:title>
									<i class="fa fa-bell-o"></i> {{ $t('LeadAlert') }}
									<span :content="$t('AlertDesc')" info-tooltip="hover me"><i class="fa fa-info-circle"></i></span>
								</template>
								<div class="col-md-8 mx-auto">
									<div class="row mb-2" v-if="notificationArray.id">
										<div class="col-12 mb-2">
											<b-form-checkbox v-model="notificationArray.active"  name="check-button" switch size="lg" class="mt-1">{{ notificationArray.active ? $t('Enabled') : $t('Disabled') }}</b-form-checkbox>
										</div>
										<div class="col-12">
											<lead-alert-tabs :recipentAdded.sync="recipentAdded" :notificationArray="notificationArray"></lead-alert-tabs>
										</div>
										<div class="col-12 text-right mb-5">
											<button class="btn btn-success" @click="saveLeadAlert()"  v-if="notificationArray.active == true && recipentAdded == true">
												<i class="fa fa-spinner fa-spin" v-if="loading"></i> 
												<i class="fa fa-cogs" v-else></i> {{ $t('Save') }} 
											</button>
											<button class="btn btn-success" @click="saveLeadAlert()" disabled  v-else>
												<i class="fa fa-spinner fa-spin" v-if="loading"></i> 
												<i class="fa fa-cogs" v-else></i> {{ $t('Save') }} 
											</button>
										</div>
									</div>
								</div>
							</b-tab>
							<b-tab :active="(tabsActive == 'email') ? true : false">
								<template v-slot:title>
									<i class="fa fa-envelope-o"></i> {{ $t('WelcomeEmail') }}
									<span :content="$t('engageYourLead')" info-tooltip="hover me"><i class="fa fa-info-circle"></i></span>
								</template>
								<div class="col-md-8 mx-auto">
									<div class="row mb-2" v-if="confirmationArray.id">
										<div class="col-12 mb-2"><!-- -->
											<b-form-checkbox v-model="confirmationArray.active" name="check-button" switch size="lg" class="mt-1">{{ confirmationArray.active ? $t('Enabled') : $t('Disabled') }}</b-form-checkbox>
										</div>
										<div class="col-12">
											<welcome-email-tabs :confirmationChanged.sync="confirmationChanged" :confirmationArray="confirmationArray"></welcome-email-tabs>
										</div>
										<div class="col-12 text-right mb-5">
											<button class="btn btn-success" @click="saveWelcomeEmail()" v-if="confirmationArray.active == true && confirmationChanged == true">
												<i class="fa fa-spinner fa-spin" v-if="loading"></i> 
												<i class="fa fa-cogs" v-else></i> {{ $t('Save') }}  
											</button>
											<button class="btn btn-success" @click="saveWelcomeEmail()" v-else disabled>
												<i class="fa fa-spinner fa-spin" v-if="loading"></i> 
												<i class="fa fa-cogs" v-else></i> {{ $t('Save') }}  
											</button>
										</div>
									</div>
								</div>
							</b-tab>
						</b-tabs>
					</div>
					
					
				</div>
			</div>
		</div>
        
    </div>
</template>
<script>
	// import sourceSettings from '@/components/sections/SourceSettings.vue';
	
	import findIndex from 'lodash/findIndex'
	import each from 'lodash/each'
	
	// import FacebookComponents from '@/components/sections/FacebookConnect.vue'
	import welcomeEmailTabs from '@/components/sections/WelcomeEmailTabs.vue'
	import leadAlertTabs from '@/components/sections/LeadAlertTabs.vue'
	import FieldsMapping from '@/components/sections/FieldsMapping.vue'
	
	
	export default {
		name: 'EditSources',
		data() {
			return {
				//
				// tabs			: '',
				// selectedSource	: {},
				tabsActive		: '',
				selectedSource	: {},
				
				questionsFields	: [],
				fields			: {},
				fieldsLabel		: {},
				loading			: false,
				
				tabsArray 		: [ 'email', 'alert', 'edit', 'webhook_edit' ],
				mapping_fields 	: [
					{ 'label' : 'Full name', 		'key' : 'full_name', 			'selected' : false },
					{ 'label' : 'First name', 		'key' : 'personal_first_name', 	'selected' : false },
					{ 'label' : 'Last Name', 		'key' : 'personal_last_name', 	'selected' : false },
					{ 'label' : 'Email', 			'key' : 'email', 				'selected' : false },
					{ 'label' : 'Phone number', 	'key' : 'personal_phone', 		'selected' : false },
					{ 'label' : 'Created On', 		'key' : 'added_at', 			'selected' : false },
					
					{ 'label' : 'Custom field', 	'key' : 'custom_field', 		'selected' : false },
				],
				notificationArray: {
					id			: '',
					active		: true,
					type 		: 'notification',
					subject		: '',
					from_email	: '',
					from_name	: '',
					recipients	: [],
					content		: '',
				},
				confirmationArray: {
					id			: '',
					active		: false,
					type		: 'confirmation',
					from_email	: '',
					from_name	: '',
					subject		: '',
					content		: '',
					sender_email: '',
					sender_name : '',
				},
				WebhookLink		: null,
				recipentAdded:false,
				confirmationChanged:false,
			}
		},
		methods: {
			
			notifyMe(message, variant){
				this.$notify({
					group	: 'foo',
					// title: 'Important message',
					text	: message,
					type	: variant,
					// duration: 500000,
					// speed	: 1000
				});
				
			},
			
			updateFieldValue(key, value, label) {
                let that = this
				that.$set(that.fields, key, value);
				that.$set(that.fieldsLabel, key, label);
                
                that.resetSelectedFields();
				
				each(that.fields, (field) => {
                    let fieldIndex = findIndex(that.mapping_fields, {key: field});
					if( typeof( that.mapping_fields[fieldIndex] ) != 'undefined' ) {
						that.mapping_fields[fieldIndex].selected = true;
					}
                })
				
            },
            
            resetSelectedFields() {
                each(this.mapping_fields, (field) => {
                    field.selected = false
                });
            },
			
            saveWelcomeEmail() {
                
				let that = this
                that.loading = true;
                let confirmation = that.confirmationArray;
                // console.log(confirmation)
                // console.log(that.selectedSource.id)
				
				let url		= 'welcome_email/' + that.selectedSource.id
				let method	= 'PATCH'
				let data	= confirmation
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then(() => {
					that.loading = false
					that.notifyMe(this.$t('ChangesSaved'), 'success')
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.loading = false
				})
				
            },
			
            saveLeadAlert() {
                
				let that = this
                that.loading = true;
                let notification = that.notificationArray;
                // console.log(notification)
                // console.log(that.selectedSource.id)
				
				let url		= 'lead_alert/' + that.selectedSource.id
				let method	= 'PATCH'
				let data	= notification
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then(() => {
					that.loading = false
					that.notifyMe(this.$t('ChangesSaved'), 'success')
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.loading = false
				})
				
            },
			
            saveSettings() {
                
				let that	 = this
                that.loading = true
				
				let url		= 'change_facebook_source/' + that.selectedSource.id
				let method	= 'PATCH'
				let data	= {'questions':that.questionsFields, 'fields':that.fields, 'fieldsLabel':that.fieldsLabel, provider : "edit sources", permissionid : 2}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then(() => {
					that.loading = false
					that.notifyMe(this.$t('ChangesSaved'), 'success')
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.loading = false
				})
				
            },
			
			autoMapping() {
                let that = this;
				let fieldArray = {
					'first_name'  :'personal_first_name',
					'last_name'	  :'personal_last_name',
					'full_name'	  :'full_name',
					'email'		  :'email',
					'phone_number':'personal_phone'
				};
				
				each( that.questionsFields, (qualifier) => {
					
					if( typeof fieldArray[ qualifier.key ] !== "undefined" ) {
						each(that.fields, (field) => {
							let fieldIndex = findIndex(that.mapping_fields, {key: field});
							if( typeof( that.mapping_fields[fieldIndex] ) != 'undefined' ) {
								that.mapping_fields[fieldIndex].selected = true;
							}
						})
					}
					
				});
            },
			
		},
		mounted() {
			let that = this
			
			that.tabsActive		= that.$route.query.action
			that.selectedSource = that.$route.query.source
			that.WebhookLink 	= that.$route.query.source.link
			
			if( !that.tabsArray.includes(that.tabsActive) || typeof( that.selectedSource.sourceData ) == 'undefined' ) {
				that.$router.push({name: 'mySources'})
				return false
			}
			
			// console.log( that.$route.query.source )
			// console.log( that.$route.query.action )
			
			if( that.selectedSource.notification != null ) { 
				that.notificationArray.id			= that.selectedSource.notification.id
				that.notificationArray.active		= that.selectedSource.notification.active == 1 ? true : false
				that.notificationArray.type 		= that.selectedSource.notification.type
				that.notificationArray.subject		= that.selectedSource.notification.subject
				that.notificationArray.from_email	= that.selectedSource.notification.from_email
				that.notificationArray.from_name	= that.selectedSource.notification.from_name
				that.notificationArray.recipients	= that.selectedSource.notification.recipients
				that.notificationArray.content		= that.selectedSource.notification.content
			}
			let content = (that.selectedSource.confirmation != null) ? that.selectedSource.confirmation.content.toString() : ''
			if( that.selectedSource.confirmation != null ) {
				that.confirmationArray.id			= that.selectedSource.confirmation.id
				that.confirmationArray.active		= that.selectedSource.confirmation.active == 1 ? true : false
				that.confirmationArray.type 		= that.selectedSource.confirmation.type
				that.confirmationArray.subject		= that.selectedSource.confirmation.subject
				that.confirmationArray.from_email	= that.selectedSource.confirmation.from_email
				that.confirmationArray.from_name	= that.selectedSource.confirmation.from_name
				that.confirmationArray.content		= content
				
				that.confirmationArray.sender_email		= that.selectedSource.confirmation.sender_email
				that.confirmationArray.sender_name		= that.selectedSource.confirmation.sender_name
			}
			
			if( that.tabsActive != 'webhook_edit' ) {
				if(that.selectedSource.type != 'zapier_app') {
					that.questionsFields = that.selectedSource.sourceData.form.questions
					that.fields			 = that.selectedSource.sourceData.fields
					that.fieldsLabel	 = that.selectedSource.sourceData.fieldsLabels
				}
				// let rcpt = that.selectedSource.notification.recipients
				// rcpt = rcpt.replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '"$2": ');
				// rcpt = JSON.parse(rcpt);
				
				that.autoMapping()
			}
		},
		components: {
			// sourceSettings
			
			// FacebookComponents,
			welcomeEmailTabs,
			leadAlertTabs,
			FieldsMapping
			
		}
	}
</script>

<style lang="scss">
	
	.input-search-zone {
		.fa {
			position: absolute;
			left: 26px;
			top: 12px;
			color: #dddddd;
		}
		
		
		.input-searchZone {
			padding-left: 32px;
			font-size: 13px;
			height: 42px;
			&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
				font-size: 12px;
			}
			
			&:-ms-input-placeholder { /* Internet Explorer 10-11 */
				font-size: 12px;
			}
			
			&::-ms-input-placeholder { /* Microsoft Edge */
				font-size: 12px;
			}
		}
		
	}
	.sync-text {
		text-align: center;
		margin-bottom: 4em;
		margin-top: 20px;
		font-size: 0.9rem;
		color: #2e4067;
		span {
			text-decoration: underline;
		}
	}
	
	
	
	
	
	[info-tooltip] {
		position:relative;
	}
	[info-tooltip]:before {
		position: absolute;
		right: -42px;
		top: -80px;
		background-color: #171717;
		color: #ffffff;
		height: 6em;
		border-radius: 5px;
		padding: 6px 6px;
		content: attr(content);
		display: none;
		transition: 0.25s ease-in-out;
		font-weight: 600;
		/* min-width: 29%; */
		min-width: 13em;
		font-size: 12px;
		z-index: 999;
	}
	[info-tooltip]:after {
		position: absolute;
		right: 45%;
		top: -8px;
		border-top: 7px solid #171717;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		content: "";
		display: none;
		transition:.25s ease-in-out;
	}
	[info-tooltip]:hover:after, [info-tooltip]:hover:before {
		display: block;
		transition:.25s ease-in-out;
	}
	
</style>
