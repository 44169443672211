<template>
    <div class="container-fluid mySources">
		
		<div class="event_processing" v-show="loadingData">
			<!--i class="fa fa-spinner fa-spin fa-3x fa-fw"></i-->
			<img src="/img/ajax-loader.gif" />
		</div>
		<div style="padding: 16px 15px 16px 10px;">
			<div class="row topfilter">
				<div class="col-5 my-auto title"  v-if="!$store.state.active || ($store.state.active && 'list sources' in $store.state.permissions)">
					<span>{{ $t('LeadSources') }}</span> <router-link :to="{ name : 'newSource' }" v-if="!$store.state.active || ($store.state.active && 'add sources' in $store.state.permissions)"><i class="fa fa-plus-circle" style="color:#377cff;font-size: 17px;margin-left: 7px;"></i></router-link>
				</div>
				<div class="col-7"  v-if="!$store.state.active || ($store.state.active && 'list sources' in $store.state.permissions)">
					<div class="form-inline" style="justify-content: flex-end;">
						<div class="form-group mr-3" style="width: 200px;">
							<date-range-picker  :locale-data="locale" ref="picker" :showWeekNumbers="true" :showDropdowns="true" v-model="dateRange" @update="getSources('filter')">
								<template v-slot:input="picker" style="min-width: 350px;">
									<div :title="dateRange.startDate">
										<i class="fa fa-calendar pr-1 d-block"></i>
										<span class="d-block" v-if="dateRange.startDate">{{ dateRange.startDate | date }} - {{ dateRange.endDate | date }}</span>
										<span class="d-block" v-else>{{ $t('AllTime') }}</span>
										<i class="fa fa-close init-dateRange" @click="InitDateRange" v-if="Object.keys(dateRange).length > 0"></i>
									</div>
								</template>
							</date-range-picker>
						</div>
						<div class="form-group input-search my-auto position-relative">
							<i class="fa fa-search"></i>
							<input type="text" class="form-control input-searchZone" v-model="searchQuery" @keyup="getSources('filter')" :placeholder="$t('Search')" />
						</div>
					</div>
					<!--div class="row">
						<div class="col-5 my-auto"></div>
						<div class="col-7"></div>
					</div-->
				</div>
			</div>
			
			<div class="row"  v-if="!$store.state.active || ($store.state.active && 'list sources' in $store.state.permissions)">
				<div class="col-md-12">
					<!-- mySources <button clqss="btn btn-primary" @click="getData()">Event</button> -->
					<!-- :searchQuery="searchQuery" -->
					<data-table :mySources="mySources" :columns="gridColumns" :selectedAction="selectedAction" @eventCallBack="eventCallBack"></data-table> <!--  -->
					
					<router-link :to="{ name : 'newSource' }" v-if="mySources.length == 0"><!--  -->
						<h5 class="sync-text" v-if="mySources.length == 0">
							<span>{{ $t('Sync') }}</span> {{ $t('SyncTitle') }}
						</h5>
					</router-link>
				</div>
			</div>
			<div v-if="$store.state.active && !Object.keys($store.state.permissions).includes('list sources')">
				<h5 class="sync-text">
					<img src="/img/svg/info-circle.svg" />
					<span style="text-decoration:none"> {{ $t('Noaccess') }}</span>
				</h5>
			</div>
		</div>
        
    </div>
</template>
<script>
	import dataTable from '@/plugins/md/dataTable/datatable-mysources.vue';
	
	import DateRangePicker from 'vue2-daterange-picker'
	import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
	
	export default {
		name: 'mySources',
		data() {
			return {
				//
				loadingData		: false,
				
				leadGetter: true,
				searchQuery: '',
				dateRange	: {},
				mySources: [],
				gridColumns: [
					{ title: this.$t('Source')			, column: 'source' 		},
					{ title: this.$t('Leads')			, column: 'leads'	  	},
					{ title: this.$t('ProcessingRate')	, column: 'processingRate'		  	  },
					{ title: this.$t('QualificationRate')		, column: 'qualificationRate' },
					{ title: this.$t('Actions')			, column: 'actions'		}
				],
				
				// gridData: [],
				current_page: 1,
				last_page	: 2,
				selectedAction : [],
				locale : {}
			}
		},
		methods: {
			
			InitDateRange () {
				let that = this
			if(localStorage.getItem('i18n_lang') == 'fr'){
					this.locale = {	}
				that.dateRange = {direction: 'ltr',
					format: 'dd/mm/yyyy',
					separator: ' - ',
					applyLabel: 'Appliquer',
					cancelLabel: 'Annuler',
					weekLabel: 'S',
					daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
					monthNames: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juill', 'Août', 'Sept', 'Oct', 'Nov', 'Déc']}
				}
				that.getSources('filter')
			},
			
			eventCallBack(action, source) {
				let that = this
				that.$router.push({path: '/editSource/' + source.id, query: {source: source, action: action}})
			},
			getSources(action = '') {
				let that = this
				// that.arrayResult.data = []
				that.loadingData = true
				if(action == 'filter') {
					that.current_page = 1
				}
				
				that.leadGetter = false
				
				let url   = 'sources?page=' + that.current_page
				let method= 'POST'
				let data  = {range:that.dateRange, keyword:that.searchQuery}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					if(action == 'filter') {
						that.mySources = []
					}
					let mySources = response.data.mySources.data;
					mySources.forEach( function(sourceData) {
						that.mySources.push(
							{
								id 				 : sourceData.id,
								source 			 : sourceData.name,
								type 			 : sourceData.type,
								created_at 		 : sourceData.created_at,
								leads			 : sourceData.leads,
								processingRate	 : sourceData.processed_rate,
								qualificationRate: sourceData.qualified_rate,
								actions			: 'actions',
								sourceData		: sourceData.data,
								confirmation	: sourceData.confirmation,
								notification	: sourceData.notification,
								link	: sourceData.link,
							}
						)
					} );
					that.current_page += 1;
					that.last_page = response.data.mySources.last_page;
					that.leadGetter = true;
					
					that.loadingData = false
					
				}).catch(( {response} ) => {
					console.log(response)
					that.loadingData = false
				})
				
			},
		},
		mounted() {
			
			let that = this
				
			that.getSources()
			
			if ( that.$route.name == "mySources" ) {
				window.onscroll = () => {
					// let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
					let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + 5 >= document.documentElement.offsetHeight;
					if ( bottomOfWindow && that.last_page >= that.current_page && that.leadGetter == true ) {
						that.getSources()
					}
				};
			}
			
		},
		components: {
			dataTable,
			DateRangePicker
		},
		watch: {
			dateRange: function (val) {
				if(val == null) {
					this.getSources('filter');
				}
			},
		},
		filters: {
			date: function(myDate) {
				if (!myDate) { return '(n/a)'; }
				let str = new Date(myDate);
				return ((str.getMonth() < 9) ? '0' : '') + (str.getMonth() + 1) + '/' +  ((str.getDate() < 10) ? '0' : '') + str.getDate() + '/' + str.getFullYear();
			}
		}
	}
</script>

<style lang="scss">
	.input-search {
		
		.fa-search {
			position: absolute;
			left: 10px;
			font-size: 13px;
			color: #ddd;
		}
		
		.input-searchZone {
			padding-left: 25px;
			font-size: 12px;
			height: 40px;
			
			&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
				font-size: 12px;
			}
			
			&:-ms-input-placeholder { /* Internet Explorer 10-11 */
				font-size: 12px;
			}
			
			&::-ms-input-placeholder { /* Microsoft Edge */
				font-size: 12px;
			}
		}
		
	}
	.sync-text {
		text-align: center;
		margin-bottom: 4em;
		margin-top: 20px;
		font-size: 0.9rem;
		color: #2e4067;
		span {
			text-decoration: underline;
		}
	}
	
	.mySources {
		.daterangepicker.openscenter {
			transform: translate(-85%) !important;
		}
	}
	
</style>
